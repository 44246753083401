<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <h2 class="brand-text text-primary">
          로그인
        </h2>

        <b-card-title class="auth-message">
          {{
            "프로젝트X에서 광고 등록부터 관리까지,\n진정한 통합 광고 솔루션을 시작해보세요!"
          }}
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group label-for="email">
              <validation-provider
                #default="{ errors }"
                name="이메일"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="이메일 주소를 입력하세요"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="비밀번호"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="비밀번호를 입력하세요"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                자동 로그인
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              로그인
            </b-button>
          </b-form>
        </validation-observer>

        <div class="d-flex justify-content-between mt-1">
          <b-link :to="{name:'auth-register'}">
            <small>회원가입 바로 가기</small>
          </b-link>
          <b-link :to="{name:'auth-forgot-password'}">
            <small>아이디 찾기/비밀번호 변경</small>
          </b-link>
        </div>

        <!-- modal -->
        <b-modal
          id="modal-danger"
          v-model="modalShow"
          ok-only
          ok-variant="danger"
          ok-title="확인"
          modal-class="modal-danger"
          centered
          title="회원님의 아이디/비밀번호가 일치하지 않습니다."
        >
          <b-card-text>
            확인 후 다시 로그인해주세요.
          </b-card-text>
        </b-modal>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BInputGroup, BInputGroupAppend, BFormCheckbox,
  BModal, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import usePassword from '@/views/utils/usePassword'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { getAbility } from '@/libs/acl/ability'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BModal,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: this.$store.state.auth.initialEmail ?? '',
      password: '',
      status: '',
      modalShow: false,
    }
  },
  methods: {
    // can't use composition API because of ACL(@casl/vue) plugin
    // doesn't support composition API in vue2 version package
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.userEmail,
            password: this.password,
          })
            .then(response => {
              this.$store.dispatch('auth/onAuthenticated', response).then(decoded => {
                this.$ability.update(getAbility())
                return this.$router.replace(getHomeRouteForLoggedInUser(decoded.role))
              })
            }).catch(() => {
              this.modalShow = true
            })
        }
      })
    },
  },
  setup() {
    const {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    } = usePassword()

    return {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
      // validation rules
      required,
      email,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
input[type=password] {font-family:'Malgun gothic', dotum, sans-serif;}
.auth-message {
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 40px;
  line-height: 2rem;
}
.modal-footer {
  border-top: 0 none;
}
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
